<template>
  <div class="resultPage">
    <div class="resultPage_img"><img src="../assets/result.png" alt="" /></div>
    <div class="resultPage_title">问题提交成功！</div>
    <div class="resultPage_title">相关人员会尽快处理，十分感谢！</div>
    <div class="resultPage_btn" @click="goBack">确定</div>
  </div>
</template>

<script>
import { useRouter } from "vue-router";
export default {
  name: "ResultPage",
  components: {},
  setup() {
    const router = useRouter();
    const goBack = () => {
      router.go(-1);
    };
    return {
      goBack,
    };
  },
};
</script>

<style lang="scss">
.resultPage {
  background: #fff;
  width: 100%;
  height: auto;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .resultPage_img {
    width: 62px;
    height: 60px;
    margin: 153px 0 33px 0;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .resultPage_title {
    color: #333333;
    font-size: 16px;
    font-weight: bold;
    line-height: 30px;
  }
  .resultPage_btn {
    width: 154px;
    height: 43px;
    background: #6643ff;
    opacity: 1;
    border-radius: 7px;
    color: #fff;
    font-size: 16px;
    line-height: 43px;
    text-align: center;
    margin-top: 150px;
  }
}
</style>
